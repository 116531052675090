import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin-top: 30px;
    margin-bottom: 70px;
    display: block;
    width: calc(100% - 128px);
    margin-left: 64px;
    margin-right: 64px;
    user-select: none;
    @media screen and (max-width: 768px) {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 30px;
        margin-bottom: 30px;
        width: calc(100% - 48px);
    }
`;

const Heading = styled.div`
    font-size: 36px;
    letter-spacing: -1.5px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-family: 'Renogare-Regular';
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    white-space: pre-wrap;
    @media screen and (max-width: 768px) {
        font-size: 26px;
        line-height: 32px;
        letter-spacing: -1px;
        color: white;
        margin-top: 0;
        margin-bottom: 23px;
        text-align: left;
    }
`;
const FeatureContainer = styled.div`
    margin-top: 29px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
    }
`;
const Feature = styled.div`
    width: calc(33.33% - 66px);
    text-align: left;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: row;
    padding: 32px;
    padding: 20px 30px 30px 30px;
    &:nth-child(3n) {
        border-right: none;
    }
    &:nth-child(n + 4) {
        border-bottom: none;
        padding: 30px 30px 20px 30px;
        @media screen and (max-width: 767px) {
            padding: 0;
        }
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        border: none;
        padding: 0;
    }
`;
const FeatureImg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 64px;
    @media screen and (max-width: 767px) {
        min-width: 52px;
    }
`;
const FeatureContent = styled.div`
    width: 100%;
    margin-left: 20px;
    @media screen and (max-width: 767px) {
        /* margin: 0 0 30px 0; */
        padding-bottom: 23px;
        margin-bottom: 23px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        ${Feature}:last-child > & {
            border-bottom: none;
        }
    }
`;
const FeatureHeading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: white;
    text-align: left;
    @media screen and (max-width: 767px) {
    }
`;
const FeatureDesc = styled.div`
    margin-top: 8px;
    text-align: left;
    font-family: 'Neuzeit Grotesk';
    font-size: 15px;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.7);
    @media screen and (max-width: 767px) {
    }
`;
const Desc = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    letter-spacing: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 34px;
    max-width: 640px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 767px) {
        margin-top: 26px;
        text-align: left;
    }
`;

const Highlight = ({ title, description, image }) => {
    return (
        <Feature>
            <FeatureImg>
                <img width="52px" src={image} alt={title}></img>
            </FeatureImg>
            <FeatureContent>
                <FeatureHeading>{title}</FeatureHeading>
                <FeatureDesc>{description}</FeatureDesc>
            </FeatureContent>
        </Feature>
    );
};
export default function ProductsHighlight({ title, description, features }) {
    return (
        <Container>
            <Heading>{title}</Heading>
            {!!description && <Desc>{description}</Desc>}

            <FeatureContainer>
                {features.map(feature => {
                    return <Highlight key={feature.title} {...feature} />;
                })}
            </FeatureContainer>
        </Container>
    );
}
