import React from 'react';
import styled from 'styled-components';
import useMedia from 'use-media';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import GoldenButton from 'components/buttons/GoldenButton';
import HollowButton from 'components/buttons/HollowButton';

const Container = styled.div`
    margin: 0;
    color: white;
    text-align: center;
    width: 100%;
    height: 100vh;
    position: relative;
    @media screen and (max-width: 1440px) {
    }
`;
const Content = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    @media screen and (max-width: 767px) {
    }
`;

const Title = styled.div`
    margin-top: auto;
    font-size: 80px;
    line-height: 80px;
    margin-bottom: 33px;

    width: ${props => (props.img ? '440px' : 'auto')};
    height: ${props => (props.img ? '114px' : 'auto')};
    max-width: 740px;

    background: url(${props => props.img}) no-repeat center;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Renogare-Regular';
    user-select: none;
    letter-spacing: -2px;
    text-align: center;
    @media screen and (max-width: 767px) {
        font-size: 44px;
        line-height: 48px;
        letter-spacing: -2.29px;
        margin-bottom: ${props => (props.type === 0 ? '98px' : '25px')};
        max-width: 100%;
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
        background: url(${props => props.img}) no-repeat center;
        background-size: contain;
    }
`;
const Desc = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 23px;
    line-height: 32px;
    letter-spacing: normal;
    user-select: none;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 767px) {
        max-width: 100%;
        margin-left: 24px;
        margin-right: 24px;
    }
`;
const ButtonsContainer = styled.div`
    margin-top: 34px;
    display: flex;
    justify-content: center;
    margin-bottom: -80px;
    & > * {
        margin: 0 8px;
    }
    @media screen and (max-width: 767px) {
        margin-top: 34px;
    }
`;

const DesktopLayout = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    position: relative;

    & > div {
        padding-top: 0 !important;
        div {
            display: none;
        }
        button {
            display: none;
        }
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            outline: none;
            user-select: none;
        }
    }

    z-index: 0;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const MobileLayout = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 0;
    }
`;
const MaskContainer = styled.div`
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    img {
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        img {
            width: auto;
            height: 100px;
        }
        margin-bottom: -1px;
    }
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
`;
export default function Hero4({
    title,
    description,
    image,
    buttons = [],
    desktop_image,
    mobile_image,
    mask_image,
    type = 0,
    back = 'bright',
}) {
    const isWide = useMedia({ minWidth: 767 });

    return (
        <Container>
            <Content>
                <Title img={image} type={type}>
                    {title}
                </Title>
                <Desc>{description}</Desc>
                <ButtonsContainer>
                    {buttons.map(button => {
                        return (
                            <React.Fragment key={button.title}>
                                {button.theme === 'golden' ? (
                                    <GoldenButton {...button}>
                                        {button.title}
                                    </GoldenButton>
                                ) : button.theme === 'hollow' ? (
                                    <HollowButton {...button}>
                                        {button.title}
                                    </HollowButton>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </ButtonsContainer>

                <MaskContainer>
                    <img
                        src={mask_image}
                        alt=""
                        style={{ objectFit: 'cover' }}
                    />
                </MaskContainer>
            </Content>
            {isWide ? (
                <DesktopLayout>
                    <Overlay />
                    <LazyLoadImage
                        alt={title}
                        effect="blur"
                        src={desktop_image} // use normal <img> attributes as props
                        width={'100%'}
                        height={'100%'}
                        style={{ objectFit: 'cover' }}
                    />
                </DesktopLayout>
            ) : (
                <MobileLayout>
                    <Overlay />
                    <LazyLoadImage
                        alt={title}
                        effect="blur"
                        src={mobile_image} // use normal <img> attributes as props
                        width={'100%'}
                        height={'100%'}
                        style={{ objectFit: 'cover' }}
                    />
                </MobileLayout>
            )}
        </Container>
    );
}
